/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * User service API
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
export type GetUsersStatus = (typeof GetUsersStatus)[keyof typeof GetUsersStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersStatus = {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
    BLOCKED: 'BLOCKED',
} as const;

export type GetUsersProfileTypesItem = (typeof GetUsersProfileTypesItem)[keyof typeof GetUsersProfileTypesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersProfileTypesItem = {
    Call_center_manager: 'Call_center_manager',
    Administrator: 'Administrator',
    Clinic_administrator: 'Clinic_administrator',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    Head_physician: 'Head_physician',
    Nurse: 'Nurse',
    End_user: 'End_user',
} as const;

export type GetUsersSortDirection = (typeof GetUsersSortDirection)[keyof typeof GetUsersSortDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersSortDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const;

export type GetUsersParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    sortField?: string;
    sortDirection?: GetUsersSortDirection;
    profileTypes?: GetUsersProfileTypesItem[];
    firstName?: string;
    middleName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    search?: string;
    status?: GetUsersStatus;
};

export type ExceptionDefinitionHttpResponseCode = (typeof ExceptionDefinitionHttpResponseCode)[keyof typeof ExceptionDefinitionHttpResponseCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExceptionDefinitionHttpResponseCode = {
    '100_CONTINUE': '100 CONTINUE',
    '101_SWITCHING_PROTOCOLS': '101 SWITCHING_PROTOCOLS',
    '102_PROCESSING': '102 PROCESSING',
    '103_CHECKPOINT': '103 CHECKPOINT',
    '200_OK': '200 OK',
    '201_CREATED': '201 CREATED',
    '202_ACCEPTED': '202 ACCEPTED',
    '203_NON_AUTHORITATIVE_INFORMATION': '203 NON_AUTHORITATIVE_INFORMATION',
    '204_NO_CONTENT': '204 NO_CONTENT',
    '205_RESET_CONTENT': '205 RESET_CONTENT',
    '206_PARTIAL_CONTENT': '206 PARTIAL_CONTENT',
    '207_MULTI_STATUS': '207 MULTI_STATUS',
    '208_ALREADY_REPORTED': '208 ALREADY_REPORTED',
    '226_IM_USED': '226 IM_USED',
    '300_MULTIPLE_CHOICES': '300 MULTIPLE_CHOICES',
    '301_MOVED_PERMANENTLY': '301 MOVED_PERMANENTLY',
    '302_FOUND': '302 FOUND',
    '302_MOVED_TEMPORARILY': '302 MOVED_TEMPORARILY',
    '303_SEE_OTHER': '303 SEE_OTHER',
    '304_NOT_MODIFIED': '304 NOT_MODIFIED',
    '305_USE_PROXY': '305 USE_PROXY',
    '307_TEMPORARY_REDIRECT': '307 TEMPORARY_REDIRECT',
    '308_PERMANENT_REDIRECT': '308 PERMANENT_REDIRECT',
    '400_BAD_REQUEST': '400 BAD_REQUEST',
    '401_UNAUTHORIZED': '401 UNAUTHORIZED',
    '402_PAYMENT_REQUIRED': '402 PAYMENT_REQUIRED',
    '403_FORBIDDEN': '403 FORBIDDEN',
    '404_NOT_FOUND': '404 NOT_FOUND',
    '405_METHOD_NOT_ALLOWED': '405 METHOD_NOT_ALLOWED',
    '406_NOT_ACCEPTABLE': '406 NOT_ACCEPTABLE',
    '407_PROXY_AUTHENTICATION_REQUIRED': '407 PROXY_AUTHENTICATION_REQUIRED',
    '408_REQUEST_TIMEOUT': '408 REQUEST_TIMEOUT',
    '409_CONFLICT': '409 CONFLICT',
    '410_GONE': '410 GONE',
    '411_LENGTH_REQUIRED': '411 LENGTH_REQUIRED',
    '412_PRECONDITION_FAILED': '412 PRECONDITION_FAILED',
    '413_PAYLOAD_TOO_LARGE': '413 PAYLOAD_TOO_LARGE',
    '413_REQUEST_ENTITY_TOO_LARGE': '413 REQUEST_ENTITY_TOO_LARGE',
    '414_URI_TOO_LONG': '414 URI_TOO_LONG',
    '414_REQUEST_URI_TOO_LONG': '414 REQUEST_URI_TOO_LONG',
    '415_UNSUPPORTED_MEDIA_TYPE': '415 UNSUPPORTED_MEDIA_TYPE',
    '416_REQUESTED_RANGE_NOT_SATISFIABLE': '416 REQUESTED_RANGE_NOT_SATISFIABLE',
    '417_EXPECTATION_FAILED': '417 EXPECTATION_FAILED',
    '418_I_AM_A_TEAPOT': '418 I_AM_A_TEAPOT',
    '419_INSUFFICIENT_SPACE_ON_RESOURCE': '419 INSUFFICIENT_SPACE_ON_RESOURCE',
    '420_METHOD_FAILURE': '420 METHOD_FAILURE',
    '421_DESTINATION_LOCKED': '421 DESTINATION_LOCKED',
    '422_UNPROCESSABLE_ENTITY': '422 UNPROCESSABLE_ENTITY',
    '423_LOCKED': '423 LOCKED',
    '424_FAILED_DEPENDENCY': '424 FAILED_DEPENDENCY',
    '425_TOO_EARLY': '425 TOO_EARLY',
    '426_UPGRADE_REQUIRED': '426 UPGRADE_REQUIRED',
    '428_PRECONDITION_REQUIRED': '428 PRECONDITION_REQUIRED',
    '429_TOO_MANY_REQUESTS': '429 TOO_MANY_REQUESTS',
    '431_REQUEST_HEADER_FIELDS_TOO_LARGE': '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
    '451_UNAVAILABLE_FOR_LEGAL_REASONS': '451 UNAVAILABLE_FOR_LEGAL_REASONS',
    '500_INTERNAL_SERVER_ERROR': '500 INTERNAL_SERVER_ERROR',
    '501_NOT_IMPLEMENTED': '501 NOT_IMPLEMENTED',
    '502_BAD_GATEWAY': '502 BAD_GATEWAY',
    '503_SERVICE_UNAVAILABLE': '503 SERVICE_UNAVAILABLE',
    '504_GATEWAY_TIMEOUT': '504 GATEWAY_TIMEOUT',
    '505_HTTP_VERSION_NOT_SUPPORTED': '505 HTTP_VERSION_NOT_SUPPORTED',
    '506_VARIANT_ALSO_NEGOTIATES': '506 VARIANT_ALSO_NEGOTIATES',
    '507_INSUFFICIENT_STORAGE': '507 INSUFFICIENT_STORAGE',
    '508_LOOP_DETECTED': '508 LOOP_DETECTED',
    '509_BANDWIDTH_LIMIT_EXCEEDED': '509 BANDWIDTH_LIMIT_EXCEEDED',
    '510_NOT_EXTENDED': '510 NOT_EXTENDED',
    '511_NETWORK_AUTHENTICATION_REQUIRED': '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const;

export interface ExceptionDefinition {
    code?: string;
    defaultMessage?: string;
    httpResponseCode?: ExceptionDefinitionHttpResponseCode;
    shared?: boolean;
}

export interface PasswordPatternDTO {
    digitCaseRequired?: boolean;
    enabled?: boolean;
    lowerCaseRequired?: boolean;
    minLength?: number;
    pattern?: string;
    specialCaseRequired?: boolean;
    upperCaseRequired?: boolean;
}

export interface PriceCategoryDTO {
    id?: string;
    name?: string;
}

export interface PageDTOUserDTO {
    content?: UserDTO[];
    empty?: boolean;
    totalElements?: number;
}

export interface EsiaPersonRootDTO {
    birthDate?: string;
    birthPlace?: string;
    citizenship?: string;
    eTag?: string;
    firstName?: string;
    gender?: string;
    inn?: string;
    lastName?: string;
    middleName?: string;
    rIdDoc?: number;
    snils?: string;
    status?: string;
    trusted?: boolean;
    updatedOn?: number;
    userAlreadyRegistered?: boolean;
    verifying?: boolean;
}

export interface EsiaPersonFullDTO {
    addresses?: EsiaAddressDTO[];
    contacts?: EsiaContactDTO[];
    documents?: EsiaDocumentDTO[];
    personRoot?: EsiaPersonRootDTO;
}

export type EsiaDocumentDTOVrfStu = (typeof EsiaDocumentDTOVrfStu)[keyof typeof EsiaDocumentDTOVrfStu];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EsiaDocumentDTOVrfStu = {
    NOT_VERIFIED: 'NOT_VERIFIED',
    VERIFIED: 'VERIFIED',
} as const;

export type EsiaDocumentDTOType = (typeof EsiaDocumentDTOType)[keyof typeof EsiaDocumentDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EsiaDocumentDTOType = {
    RF_PASSPORT: 'RF_PASSPORT',
    FID_DOC: 'FID_DOC',
    DRIVING_LICENSE: 'DRIVING_LICENSE',
    MLTR_ID: 'MLTR_ID',
    FRGN_PASS: 'FRGN_PASS',
    MDCL_PLCY: 'MDCL_PLCY',
    BRTH_CERT: 'BRTH_CERT',
    RF_DRIVING_LICENSE: 'RF_DRIVING_LICENSE',
    OTHER: 'OTHER',
} as const;

export interface EsiaDocumentDTO {
    expiryDate?: string;
    firstName?: string;
    id?: number;
    issueDate?: string;
    issuedBy?: string;
    issueId?: string;
    lastName?: string;
    number?: string;
    series?: string;
    type?: EsiaDocumentDTOType;
    vrfStu?: EsiaDocumentDTOVrfStu;
}

export type EsiaContactDTOVrfStu = (typeof EsiaContactDTOVrfStu)[keyof typeof EsiaContactDTOVrfStu];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EsiaContactDTOVrfStu = {
    NOT_VERIFIED: 'NOT_VERIFIED',
    VERIFIED: 'VERIFIED',
} as const;

export type EsiaContactDTOType = (typeof EsiaContactDTOType)[keyof typeof EsiaContactDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EsiaContactDTOType = {
    MBT: 'MBT',
    PHN: 'PHN',
    EML: 'EML',
    CEM: 'CEM',
} as const;

export interface EsiaContactDTO {
    id?: number;
    type?: EsiaContactDTOType;
    value?: string;
    vrfStu?: EsiaContactDTOVrfStu;
}

export type EsiaAddressDTOType = (typeof EsiaAddressDTOType)[keyof typeof EsiaAddressDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EsiaAddressDTOType = {
    PLV: 'PLV',
    PRG: 'PRG',
} as const;

export interface EsiaAddressDTO {
    additionArea?: string;
    addressStr?: string;
    area?: string;
    building?: string;
    city?: string;
    countryId?: string;
    district?: string;
    fiasCode?: string;
    flat?: string;
    frame?: string;
    house?: string;
    id?: number;
    region?: string;
    settlement?: string;
    street?: string;
    type?: EsiaAddressDTOType;
    zipCode?: string;
}

export interface EsiaResponseDTO {
    code?: string;
    error?: string;
    errorDescription?: string;
    redirectUrl?: string;
}

export interface UsernamePasswordDTO {
    esia?: boolean;
    password?: string;
    username?: string;
}

export type UserOfferRecoverPasswordDTOChannel = (typeof UserOfferRecoverPasswordDTOChannel)[keyof typeof UserOfferRecoverPasswordDTOChannel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserOfferRecoverPasswordDTOChannel = {
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
} as const;

export interface UserOfferRecoverPasswordDTO {
    channel?: UserOfferRecoverPasswordDTOChannel;
    emailOrPhone?: string;
}

export interface UserRecoverPasswordDTO {
    newPassword?: string;
    token?: string;
}

export interface OfferRegisterDTO {
    email?: string;
}

export interface CreateSessionResponseDTO {
    attempts?: number;
    codeLength?: number;
    expirationDate?: string;
    resendAfter?: string;
    sessionId?: string;
}

export interface RegisterSmsSendRequestDTO {
    afterToken?: string;
    phone?: string;
}

export type CheckCodeResponseDTODataMode = (typeof CheckCodeResponseDTODataMode)[keyof typeof CheckCodeResponseDTODataMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckCodeResponseDTODataMode = {
    DIRECT: 'DIRECT',
    TOKEN: 'TOKEN',
} as const;

export interface CheckCodeResponseDTO {
    dataMode?: CheckCodeResponseDTODataMode;
    dataOrToken?: string;
    sessionId?: string;
}

export interface CheckCodeRequestDTO {
    code?: string;
    sessionId?: string;
}

export interface UserRegisterDTO {
    birthDate?: string;
    emailTokenAfter?: string;
    firstName?: string;
    isEsia?: boolean;
    lastName?: string;
    middleName?: string;
    noMiddleName?: boolean;
    password?: string;
    phoneTokenAfter?: string;
    snils?: string;
}

export type CreateUserProfileDTOProfileType = (typeof CreateUserProfileDTOProfileType)[keyof typeof CreateUserProfileDTOProfileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateUserProfileDTOProfileType = {
    Call_center_manager: 'Call_center_manager',
    Administrator: 'Administrator',
    Clinic_administrator: 'Clinic_administrator',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    Head_physician: 'Head_physician',
    Nurse: 'Nurse',
    End_user: 'End_user',
} as const;

export type CreateUserProfileDTOGender = (typeof CreateUserProfileDTOGender)[keyof typeof CreateUserProfileDTOGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateUserProfileDTOGender = {
    male: 'male',
    female: 'female',
} as const;

export type CreateUserProfileDTOAidFamilyMemberCode =
    (typeof CreateUserProfileDTOAidFamilyMemberCode)[keyof typeof CreateUserProfileDTOAidFamilyMemberCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateUserProfileDTOAidFamilyMemberCode = {
    FAMMEMB: 'FAMMEMB',
    CHILD: 'CHILD',
    CHLDADOPT: 'CHLDADOPT',
    DAUADOPT: 'DAUADOPT',
    SONADOPT: 'SONADOPT',
    CHLDFOST: 'CHLDFOST',
    DAUFOST: 'DAUFOST',
    SONFOST: 'SONFOST',
    DAUC: 'DAUC',
    DAU: 'DAU',
    STPDAU: 'STPDAU',
    NCHILD: 'NCHILD',
    SON: 'SON',
    SONC: 'SONC',
    STPSON: 'STPSON',
    STPCHLD: 'STPCHLD',
    EXT: 'EXT',
    AUNT: 'AUNT',
    MAUNT: 'MAUNT',
    PAUNT: 'PAUNT',
    COUSN: 'COUSN',
    MCOUSN: 'MCOUSN',
    PCOUSN: 'PCOUSN',
    GGRPRN: 'GGRPRN',
    GGRFTH: 'GGRFTH',
    MGGRFTH: 'MGGRFTH',
    PGGRFTH: 'PGGRFTH',
    GGRMTH: 'GGRMTH',
    MGGRMTH: 'MGGRMTH',
    PGGRMTH: 'PGGRMTH',
    MGGRPRN: 'MGGRPRN',
    PGGRPRN: 'PGGRPRN',
    GRNDCHILD: 'GRNDCHILD',
    GRNDDAU: 'GRNDDAU',
    GRNDSON: 'GRNDSON',
    GRPRN: 'GRPRN',
    GRFTH: 'GRFTH',
    MGRFTH: 'MGRFTH',
    PGRFTH: 'PGRFTH',
    GRMTH: 'GRMTH',
    MGRMTH: 'MGRMTH',
    PGRMTH: 'PGRMTH',
    MGRPRN: 'MGRPRN',
    PGRPRN: 'PGRPRN',
    INLAW: 'INLAW',
    CHLDINLAW: 'CHLDINLAW',
    DAUINLAW: 'DAUINLAW',
    SONINLAW: 'SONINLAW',
    PRNINLAW: 'PRNINLAW',
    FTHINLAW: 'FTHINLAW',
    MTHINLAW: 'MTHINLAW',
    MTHINLOAW: 'MTHINLOAW',
    SIBINLAW: 'SIBINLAW',
    BROINLAW: 'BROINLAW',
    SISINLAW: 'SISINLAW',
    SISLINLAW: 'SISLINLAW',
    NIENEPH: 'NIENEPH',
    NEPHEW: 'NEPHEW',
    NIECE: 'NIECE',
    UNCLE: 'UNCLE',
    MUNCLE: 'MUNCLE',
    PUNCLE: 'PUNCLE',
    PRN: 'PRN',
    ADOPTP: 'ADOPTP',
    ADOPTF: 'ADOPTF',
    ADOPTM: 'ADOPTM',
    FTH: 'FTH',
    FTHFOST: 'FTHFOST',
    NFTH: 'NFTH',
    NFTHF: 'NFTHF',
    STPFTH: 'STPFTH',
    MTH: 'MTH',
    GESTM: 'GESTM',
    MTHFOST: 'MTHFOST',
    NMTH: 'NMTH',
    NMTHF: 'NMTHF',
    STPMTH: 'STPMTH',
    NPRN: 'NPRN',
    PRNFOST: 'PRNFOST',
    STPPRN: 'STPPRN',
    SIB: 'SIB',
    BRO: 'BRO',
    HBRO: 'HBRO',
    NBRO: 'NBRO',
    TWINBRO: 'TWINBRO',
    FTWINBRO: 'FTWINBRO',
    ITWINBRO: 'ITWINBRO',
    STPBRO: 'STPBRO',
    HSIB: 'HSIB',
    HSIS: 'HSIS',
    NSIB: 'NSIB',
    NSIS: 'NSIS',
    TWINSIS: 'TWINSIS',
    FTWINSIS: 'FTWINSIS',
    ITWINSIS: 'ITWINSIS',
    TWIN: 'TWIN',
    FTWIN: 'FTWIN',
    ITWIN: 'ITWIN',
    SIS: 'SIS',
    STPSIS: 'STPSIS',
    STPSIB: 'STPSIB',
    SIGOTHR: 'SIGOTHR',
    DOMPART: 'DOMPART',
    FMRSPS: 'FMRSPS',
    SPS: 'SPS',
    HUSB: 'HUSB',
    WIFE: 'WIFE',
    OTHER: 'OTHER',
} as const;

export interface CreateUserProfileDTO {
    aidFamilyMemberCode?: CreateUserProfileDTOAidFamilyMemberCode;
    birthDate?: string;
    descriptionOfRepresentative?: string;
    email?: string;
    files?: string[];
    firstName?: string;
    gender?: CreateUserProfileDTOGender;
    lastName?: string;
    middleName?: string;
    phone?: string;
    profileType?: CreateUserProfileDTOProfileType;
    snils?: string;
}

export interface CreateUserDTO {
    birthDate?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    mobile?: string;
    snils?: string;
}

export type UpdateProfileDTOQualificationCategory =
    (typeof UpdateProfileDTOQualificationCategory)[keyof typeof UpdateProfileDTOQualificationCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateProfileDTOQualificationCategory = {
    first: 'first',
    second: 'second',
    highest: 'highest',
} as const;

export type UpdateProfileDTOPriceCategory = (typeof UpdateProfileDTOPriceCategory)[keyof typeof UpdateProfileDTOPriceCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateProfileDTOPriceCategory = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type UpdateProfileDTOPractitionerSpecialtiesItem =
    (typeof UpdateProfileDTOPractitionerSpecialtiesItem)[keyof typeof UpdateProfileDTOPractitionerSpecialtiesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateProfileDTOPractitionerSpecialtiesItem = {
    ophthalmologist: 'ophthalmologist',
} as const;

export type UpdateProfileDTOPractitionerChiefComplaintCodesItem =
    (typeof UpdateProfileDTOPractitionerChiefComplaintCodesItem)[keyof typeof UpdateProfileDTOPractitionerChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateProfileDTOPractitionerChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type UpdateProfileDTOPlanDefinitionIdsItem =
    (typeof UpdateProfileDTOPlanDefinitionIdsItem)[keyof typeof UpdateProfileDTOPlanDefinitionIdsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateProfileDTOPlanDefinitionIdsItem = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type UpdateProfileDTOGender = (typeof UpdateProfileDTOGender)[keyof typeof UpdateProfileDTOGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateProfileDTOGender = {
    male: 'male',
    female: 'female',
} as const;

export type UpdateProfileDTOAidFamilyMemberCode = (typeof UpdateProfileDTOAidFamilyMemberCode)[keyof typeof UpdateProfileDTOAidFamilyMemberCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateProfileDTOAidFamilyMemberCode = {
    FAMMEMB: 'FAMMEMB',
    CHILD: 'CHILD',
    CHLDADOPT: 'CHLDADOPT',
    DAUADOPT: 'DAUADOPT',
    SONADOPT: 'SONADOPT',
    CHLDFOST: 'CHLDFOST',
    DAUFOST: 'DAUFOST',
    SONFOST: 'SONFOST',
    DAUC: 'DAUC',
    DAU: 'DAU',
    STPDAU: 'STPDAU',
    NCHILD: 'NCHILD',
    SON: 'SON',
    SONC: 'SONC',
    STPSON: 'STPSON',
    STPCHLD: 'STPCHLD',
    EXT: 'EXT',
    AUNT: 'AUNT',
    MAUNT: 'MAUNT',
    PAUNT: 'PAUNT',
    COUSN: 'COUSN',
    MCOUSN: 'MCOUSN',
    PCOUSN: 'PCOUSN',
    GGRPRN: 'GGRPRN',
    GGRFTH: 'GGRFTH',
    MGGRFTH: 'MGGRFTH',
    PGGRFTH: 'PGGRFTH',
    GGRMTH: 'GGRMTH',
    MGGRMTH: 'MGGRMTH',
    PGGRMTH: 'PGGRMTH',
    MGGRPRN: 'MGGRPRN',
    PGGRPRN: 'PGGRPRN',
    GRNDCHILD: 'GRNDCHILD',
    GRNDDAU: 'GRNDDAU',
    GRNDSON: 'GRNDSON',
    GRPRN: 'GRPRN',
    GRFTH: 'GRFTH',
    MGRFTH: 'MGRFTH',
    PGRFTH: 'PGRFTH',
    GRMTH: 'GRMTH',
    MGRMTH: 'MGRMTH',
    PGRMTH: 'PGRMTH',
    MGRPRN: 'MGRPRN',
    PGRPRN: 'PGRPRN',
    INLAW: 'INLAW',
    CHLDINLAW: 'CHLDINLAW',
    DAUINLAW: 'DAUINLAW',
    SONINLAW: 'SONINLAW',
    PRNINLAW: 'PRNINLAW',
    FTHINLAW: 'FTHINLAW',
    MTHINLAW: 'MTHINLAW',
    MTHINLOAW: 'MTHINLOAW',
    SIBINLAW: 'SIBINLAW',
    BROINLAW: 'BROINLAW',
    SISINLAW: 'SISINLAW',
    SISLINLAW: 'SISLINLAW',
    NIENEPH: 'NIENEPH',
    NEPHEW: 'NEPHEW',
    NIECE: 'NIECE',
    UNCLE: 'UNCLE',
    MUNCLE: 'MUNCLE',
    PUNCLE: 'PUNCLE',
    PRN: 'PRN',
    ADOPTP: 'ADOPTP',
    ADOPTF: 'ADOPTF',
    ADOPTM: 'ADOPTM',
    FTH: 'FTH',
    FTHFOST: 'FTHFOST',
    NFTH: 'NFTH',
    NFTHF: 'NFTHF',
    STPFTH: 'STPFTH',
    MTH: 'MTH',
    GESTM: 'GESTM',
    MTHFOST: 'MTHFOST',
    NMTH: 'NMTH',
    NMTHF: 'NMTHF',
    STPMTH: 'STPMTH',
    NPRN: 'NPRN',
    PRNFOST: 'PRNFOST',
    STPPRN: 'STPPRN',
    SIB: 'SIB',
    BRO: 'BRO',
    HBRO: 'HBRO',
    NBRO: 'NBRO',
    TWINBRO: 'TWINBRO',
    FTWINBRO: 'FTWINBRO',
    ITWINBRO: 'ITWINBRO',
    STPBRO: 'STPBRO',
    HSIB: 'HSIB',
    HSIS: 'HSIS',
    NSIB: 'NSIB',
    NSIS: 'NSIS',
    TWINSIS: 'TWINSIS',
    FTWINSIS: 'FTWINSIS',
    ITWINSIS: 'ITWINSIS',
    TWIN: 'TWIN',
    FTWIN: 'FTWIN',
    ITWIN: 'ITWIN',
    SIS: 'SIS',
    STPSIS: 'STPSIS',
    STPSIB: 'STPSIB',
    SIGOTHR: 'SIGOTHR',
    DOMPART: 'DOMPART',
    FMRSPS: 'FMRSPS',
    SPS: 'SPS',
    HUSB: 'HUSB',
    WIFE: 'WIFE',
    OTHER: 'OTHER',
} as const;

export type UpdateProfileDTOAcademicRank = (typeof UpdateProfileDTOAcademicRank)[keyof typeof UpdateProfileDTOAcademicRank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateProfileDTOAcademicRank = {
    full_professor: 'full_professor',
    associate_professor: 'associate_professor',
    senior_lecturer: 'senior_lecturer',
    assistant: 'assistant',
} as const;

export type UpdateProfileDTOAcademicDegree = (typeof UpdateProfileDTOAcademicDegree)[keyof typeof UpdateProfileDTOAcademicDegree];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateProfileDTOAcademicDegree = {
    candidate_of_medical_sciences: 'candidate_of_medical_sciences',
    doctor_of_medical_sciences: 'doctor_of_medical_sciences',
} as const;

export interface WorkExperienceDTO {
    description?: string;
    yearEnd?: number;
    yearStart?: number;
}

export interface UpdateProfileDTO {
    academicDegree?: UpdateProfileDTOAcademicDegree;
    academicRank?: UpdateProfileDTOAcademicRank;
    aidFamilyMemberCode?: UpdateProfileDTOAidFamilyMemberCode;
    avatarId?: string;
    birthDate?: string;
    descriptionOfRepresentative?: string;
    educationDetailsDTO?: EducationDetailsDTO[];
    email?: string;
    firstName?: string;
    gender?: UpdateProfileDTOGender;
    lastName?: string;
    middleName?: string;
    noMiddleName?: boolean;
    phone?: string;
    physiciansAssociations?: string[];
    planDefinitionIds?: UpdateProfileDTOPlanDefinitionIdsItem[];
    practitionerChiefComplaintCodes?: UpdateProfileDTOPractitionerChiefComplaintCodesItem[];
    practitionerProfessionalActivityStartYear?: number;
    practitionerSpecialties?: UpdateProfileDTOPractitionerSpecialtiesItem[];
    priceCategory?: UpdateProfileDTOPriceCategory;
    qualificationCategory?: UpdateProfileDTOQualificationCategory;
    snils?: string;
    workExperienceDTOList?: WorkExperienceDTO[];
}

export type UserProfileDetailsDTOQualificationCategory =
    (typeof UserProfileDetailsDTOQualificationCategory)[keyof typeof UserProfileDetailsDTOQualificationCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOQualificationCategory = {
    first: 'first',
    second: 'second',
    highest: 'highest',
} as const;

export type UserProfileDetailsDTOPriceCategory = (typeof UserProfileDetailsDTOPriceCategory)[keyof typeof UserProfileDetailsDTOPriceCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPriceCategory = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type UserProfileDetailsDTOPractitionerSpecialtiesItem =
    (typeof UserProfileDetailsDTOPractitionerSpecialtiesItem)[keyof typeof UserProfileDetailsDTOPractitionerSpecialtiesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPractitionerSpecialtiesItem = {
    ophthalmologist: 'ophthalmologist',
} as const;

export type UserProfileDetailsDTOPractitionerChiefComplaintCodesItem =
    (typeof UserProfileDetailsDTOPractitionerChiefComplaintCodesItem)[keyof typeof UserProfileDetailsDTOPractitionerChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPractitionerChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type UserProfileDetailsDTOPlanDefinitionIDsItem =
    (typeof UserProfileDetailsDTOPlanDefinitionIDsItem)[keyof typeof UserProfileDetailsDTOPlanDefinitionIDsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPlanDefinitionIDsItem = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode =
    (typeof UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode)[keyof typeof UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode = {
    FAMMEMB: 'FAMMEMB',
    CHILD: 'CHILD',
    CHLDADOPT: 'CHLDADOPT',
    DAUADOPT: 'DAUADOPT',
    SONADOPT: 'SONADOPT',
    CHLDFOST: 'CHLDFOST',
    DAUFOST: 'DAUFOST',
    SONFOST: 'SONFOST',
    DAUC: 'DAUC',
    DAU: 'DAU',
    STPDAU: 'STPDAU',
    NCHILD: 'NCHILD',
    SON: 'SON',
    SONC: 'SONC',
    STPSON: 'STPSON',
    STPCHLD: 'STPCHLD',
    EXT: 'EXT',
    AUNT: 'AUNT',
    MAUNT: 'MAUNT',
    PAUNT: 'PAUNT',
    COUSN: 'COUSN',
    MCOUSN: 'MCOUSN',
    PCOUSN: 'PCOUSN',
    GGRPRN: 'GGRPRN',
    GGRFTH: 'GGRFTH',
    MGGRFTH: 'MGGRFTH',
    PGGRFTH: 'PGGRFTH',
    GGRMTH: 'GGRMTH',
    MGGRMTH: 'MGGRMTH',
    PGGRMTH: 'PGGRMTH',
    MGGRPRN: 'MGGRPRN',
    PGGRPRN: 'PGGRPRN',
    GRNDCHILD: 'GRNDCHILD',
    GRNDDAU: 'GRNDDAU',
    GRNDSON: 'GRNDSON',
    GRPRN: 'GRPRN',
    GRFTH: 'GRFTH',
    MGRFTH: 'MGRFTH',
    PGRFTH: 'PGRFTH',
    GRMTH: 'GRMTH',
    MGRMTH: 'MGRMTH',
    PGRMTH: 'PGRMTH',
    MGRPRN: 'MGRPRN',
    PGRPRN: 'PGRPRN',
    INLAW: 'INLAW',
    CHLDINLAW: 'CHLDINLAW',
    DAUINLAW: 'DAUINLAW',
    SONINLAW: 'SONINLAW',
    PRNINLAW: 'PRNINLAW',
    FTHINLAW: 'FTHINLAW',
    MTHINLAW: 'MTHINLAW',
    MTHINLOAW: 'MTHINLOAW',
    SIBINLAW: 'SIBINLAW',
    BROINLAW: 'BROINLAW',
    SISINLAW: 'SISINLAW',
    SISLINLAW: 'SISLINLAW',
    NIENEPH: 'NIENEPH',
    NEPHEW: 'NEPHEW',
    NIECE: 'NIECE',
    UNCLE: 'UNCLE',
    MUNCLE: 'MUNCLE',
    PUNCLE: 'PUNCLE',
    PRN: 'PRN',
    ADOPTP: 'ADOPTP',
    ADOPTF: 'ADOPTF',
    ADOPTM: 'ADOPTM',
    FTH: 'FTH',
    FTHFOST: 'FTHFOST',
    NFTH: 'NFTH',
    NFTHF: 'NFTHF',
    STPFTH: 'STPFTH',
    MTH: 'MTH',
    GESTM: 'GESTM',
    MTHFOST: 'MTHFOST',
    NMTH: 'NMTH',
    NMTHF: 'NMTHF',
    STPMTH: 'STPMTH',
    NPRN: 'NPRN',
    PRNFOST: 'PRNFOST',
    STPPRN: 'STPPRN',
    SIB: 'SIB',
    BRO: 'BRO',
    HBRO: 'HBRO',
    NBRO: 'NBRO',
    TWINBRO: 'TWINBRO',
    FTWINBRO: 'FTWINBRO',
    ITWINBRO: 'ITWINBRO',
    STPBRO: 'STPBRO',
    HSIB: 'HSIB',
    HSIS: 'HSIS',
    NSIB: 'NSIB',
    NSIS: 'NSIS',
    TWINSIS: 'TWINSIS',
    FTWINSIS: 'FTWINSIS',
    ITWINSIS: 'ITWINSIS',
    TWIN: 'TWIN',
    FTWIN: 'FTWIN',
    ITWIN: 'ITWIN',
    SIS: 'SIS',
    STPSIS: 'STPSIS',
    STPSIB: 'STPSIB',
    SIGOTHR: 'SIGOTHR',
    DOMPART: 'DOMPART',
    FMRSPS: 'FMRSPS',
    SPS: 'SPS',
    HUSB: 'HUSB',
    WIFE: 'WIFE',
    OTHER: 'OTHER',
} as const;

export type UserProfileDetailsDTOGender = (typeof UserProfileDetailsDTOGender)[keyof typeof UserProfileDetailsDTOGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOGender = {
    male: 'male',
    female: 'female',
} as const;

export type UserProfileDetailsDTOAcademicRank = (typeof UserProfileDetailsDTOAcademicRank)[keyof typeof UserProfileDetailsDTOAcademicRank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOAcademicRank = {
    full_professor: 'full_professor',
    associate_professor: 'associate_professor',
    senior_lecturer: 'senior_lecturer',
    assistant: 'assistant',
} as const;

export type UserProfileDetailsDTOAcademicDegree = (typeof UserProfileDetailsDTOAcademicDegree)[keyof typeof UserProfileDetailsDTOAcademicDegree];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOAcademicDegree = {
    candidate_of_medical_sciences: 'candidate_of_medical_sciences',
    doctor_of_medical_sciences: 'doctor_of_medical_sciences',
} as const;

export interface UserProfileDetailsDTO {
    academicDegree?: UserProfileDetailsDTOAcademicDegree;
    academicRank?: UserProfileDetailsDTOAcademicRank;
    birthDate?: string;
    educationsDetails?: EducationDetailsDTO[];
    fhirType?: string;
    firstName?: string;
    gender?: UserProfileDetailsDTOGender;
    lastName?: string;
    middleName?: string;
    patientApproved?: boolean;
    patientOmsNumber?: string;
    patientRepresentativeFamilyMemberCode?: UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode;
    photoLink?: string;
    physiciansAssociations?: string[];
    planDefinitionIDs?: UserProfileDetailsDTOPlanDefinitionIDsItem[];
    practitionerChiefComplaintCodes?: UserProfileDetailsDTOPractitionerChiefComplaintCodesItem[];
    practitionerProfessionalActivityStartYear?: number;
    practitionerSpecialties?: UserProfileDetailsDTOPractitionerSpecialtiesItem[];
    priceCategory?: UserProfileDetailsDTOPriceCategory;
    qualificationCategory?: UserProfileDetailsDTOQualificationCategory;
    representativeMemberDescription?: string;
    snils?: string;
    supervisingPractitioner?: AboutPractitionerDTO;
    workExperiences?: WorkExperienceDTO[];
}

export type UserProfileDTOProfileType = (typeof UserProfileDTOProfileType)[keyof typeof UserProfileDTOProfileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDTOProfileType = {
    Call_center_manager: 'Call_center_manager',
    Administrator: 'Administrator',
    Clinic_administrator: 'Clinic_administrator',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    Head_physician: 'Head_physician',
    Nurse: 'Nurse',
    End_user: 'End_user',
} as const;

export interface UserProfileDTO {
    fhirId?: string;
    profileType?: UserProfileDTOProfileType;
    userProfileDetailsDTO?: UserProfileDetailsDTO;
}

export type UserDTOStatus = (typeof UserDTOStatus)[keyof typeof UserDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDTOStatus = {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
    BLOCKED: 'BLOCKED',
} as const;

export interface UserDTO {
    birthDate?: string;
    created?: number;
    email?: string;
    esia?: boolean;
    firstName?: string;
    id?: string;
    lastName?: string;
    middleName?: string;
    mobile?: string;
    status?: UserDTOStatus;
    userProfileDTOList?: UserProfileDTO[];
}

export type EducationDetailsDTOType = (typeof EducationDetailsDTOType)[keyof typeof EducationDetailsDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EducationDetailsDTOType = {
    certificate: 'certificate',
    university: 'university',
    internship: 'internship',
    accreditation: 'accreditation',
} as const;

export interface EducationDetailsDTO {
    graduationYear?: number;
    name?: string;
    type?: EducationDetailsDTOType;
}

export type AboutPractitionerDTOSpeciality = (typeof AboutPractitionerDTOSpeciality)[keyof typeof AboutPractitionerDTOSpeciality];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AboutPractitionerDTOSpeciality = {
    ophthalmologist: 'ophthalmologist',
} as const;

export type AboutPractitionerDTORoleCode = (typeof AboutPractitionerDTORoleCode)[keyof typeof AboutPractitionerDTORoleCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AboutPractitionerDTORoleCode = {
    doctor: 'doctor',
    nurse: 'nurse',
} as const;

export type AboutPractitionerDTOPriceCategoryType =
    (typeof AboutPractitionerDTOPriceCategoryType)[keyof typeof AboutPractitionerDTOPriceCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AboutPractitionerDTOPriceCategoryType = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type AboutPractitionerDTOCategory = (typeof AboutPractitionerDTOCategory)[keyof typeof AboutPractitionerDTOCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AboutPractitionerDTOCategory = {
    first: 'first',
    second: 'second',
    highest: 'highest',
} as const;

export interface AboutPractitionerDTO {
    category?: AboutPractitionerDTOCategory;
    firstName?: string;
    fullName?: string;
    id?: string;
    lastName?: string;
    middleName?: string;
    organizationName?: string;
    photo?: string;
    price?: number;
    priceCategoryType?: AboutPractitionerDTOPriceCategoryType;
    roleCode?: AboutPractitionerDTORoleCode;
    roleId?: string;
    speciality?: AboutPractitionerDTOSpeciality;
}

export type UpdateUserDTOStatus = (typeof UpdateUserDTOStatus)[keyof typeof UpdateUserDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateUserDTOStatus = {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
    BLOCKED: 'BLOCKED',
} as const;

export interface UpdateUserDTO {
    email?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    noMiddleName?: boolean;
    phone?: string;
    status?: UpdateUserDTOStatus;
}

export const authenticate = <TData = AxiosResponse<void>>(usernamePasswordDTO: UsernamePasswordDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/user-service/login`, usernamePasswordDTO, options);
};

export const addProfile = <TData = AxiosResponse<void>>(
    userId: string,
    createUserProfileDTO: CreateUserProfileDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/user-service/users/${userId}/profiles`, createUserProfileDTO, options);
};

export const testOnlyPatient = <TData = AxiosResponse<string>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/auth/test/patient`, options);
};

export const getUserUrl = <TData = AxiosResponse<string>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/oauth/esia`, options);
};

export const getExceptionClientCodes = <TData = AxiosResponse<ExceptionDefinition[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/global/definitions/exceptions`, options);
};

export const verifySmsCode = <TData = AxiosResponse<CheckCodeResponseDTO>>(
    checkCodeRequestDTO: CheckCodeRequestDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/user-service/register/phone/verify`, checkCodeRequestDTO, options);
};

export const getUser = <TData = AxiosResponse<UserDTO>>(userId: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/users/${userId}`, options);
};

export const updateUser = <TData = AxiosResponse<UserDTO>>(
    userId: string,
    updateUserDTO: UpdateUserDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/user-service/users/${userId}`, updateUserDTO, options);
};

export const initRegister = <TData = AxiosResponse<CheckCodeResponseDTO>>(
    checkCodeRequestDTO: CheckCodeRequestDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/user-service/register/init`, checkCodeRequestDTO, options);
};

export const offerRecoverPassword1 = <TData = AxiosResponse<CreateSessionResponseDTO>>(
    userOfferRecoverPasswordDTO: UserOfferRecoverPasswordDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/user-service/recover-password/offer`, userOfferRecoverPasswordDTO, options);
};

export const updateProfile = <TData = AxiosResponse<UserDTO>>(
    userId: string,
    profileFhirId: string,
    updateProfileDTO: UpdateProfileDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/user-service/users/${userId}/profiles/${profileFhirId}`, updateProfileDTO, options);
};

export const getUsers = <TData = AxiosResponse<PageDTOUserDTO>>(params?: GetUsersParams, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/users`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const createUser = <TData = AxiosResponse<UserDTO>>(createUserDTO: CreateUserDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/user-service/users`, createUserDTO, options);
};

export const logout = <TData = AxiosResponse<void>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/user-service/logout`, undefined, options);
};

export const getPriceCategories = <TData = AxiosResponse<PriceCategoryDTO[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/prices/categories`, options);
};

export const sendSmsCode = <TData = AxiosResponse<CreateSessionResponseDTO>>(
    registerSmsSendRequestDTO: RegisterSmsSendRequestDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/user-service/register/phone/send`, registerSmsSendRequestDTO, options);
};

export const testOnlyPract = <TData = AxiosResponse<string>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/auth/test/pract`, options);
};

export const refreshToken = <TData = AxiosResponse<void>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/user-service/token/refresh`, undefined, options);
};

export const getLogoutUserUrl = <TData = AxiosResponse<string>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/oauth/esia-logout`, options);
};

export const getUserInfo = <TData = AxiosResponse<EsiaPersonFullDTO>>(
    esiaResponseDTO: EsiaResponseDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/user-service/esia-login`, esiaResponseDTO, options);
};

export const getCurrentUser = <TData = AxiosResponse<UserDTO>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/users/current`, options);
};

export const testBoth = <TData = AxiosResponse<string>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/auth/test/both`, options);
};

export const offerRecoverPassword2 = <TData = AxiosResponse<CheckCodeResponseDTO>>(
    checkCodeRequestDTO: CheckCodeRequestDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/user-service/recover-password/init`, checkCodeRequestDTO, options);
};

export const getPasswordPatternMeta = <TData = AxiosResponse<PasswordPatternDTO>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/user-service/global/password-pattern`, options);
};

export const register = <TData = AxiosResponse<void>>(userRegisterDTO: UserRegisterDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/user-service/register`, userRegisterDTO, options);
};

export const offerRegister = <TData = AxiosResponse<CreateSessionResponseDTO>>(
    offerRegisterDTO: OfferRegisterDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/user-service/register/offer`, offerRegisterDTO, options);
};

export const offerRecoverPassword = <TData = AxiosResponse<void>>(
    userRecoverPasswordDTO: UserRecoverPasswordDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/user-service/recover-password`, userRecoverPasswordDTO, options);
};

export type AuthenticateResult = AxiosResponse<void>;
export type AddProfileResult = AxiosResponse<void>;
export type TestOnlyPatientResult = AxiosResponse<string>;
export type GetUserUrlResult = AxiosResponse<string>;
export type GetExceptionClientCodesResult = AxiosResponse<ExceptionDefinition[]>;
export type VerifySmsCodeResult = AxiosResponse<CheckCodeResponseDTO>;
export type GetUserResult = AxiosResponse<UserDTO>;
export type UpdateUserResult = AxiosResponse<UserDTO>;
export type InitRegisterResult = AxiosResponse<CheckCodeResponseDTO>;
export type OfferRecoverPassword1Result = AxiosResponse<CreateSessionResponseDTO>;
export type UpdateProfileResult = AxiosResponse<UserDTO>;
export type GetUsersResult = AxiosResponse<PageDTOUserDTO>;
export type CreateUserResult = AxiosResponse<UserDTO>;
export type LogoutResult = AxiosResponse<void>;
export type GetPriceCategoriesResult = AxiosResponse<PriceCategoryDTO[]>;
export type SendSmsCodeResult = AxiosResponse<CreateSessionResponseDTO>;
export type TestOnlyPractResult = AxiosResponse<string>;
export type RefreshTokenResult = AxiosResponse<void>;
export type GetLogoutUserUrlResult = AxiosResponse<string>;
export type GetUserInfoResult = AxiosResponse<EsiaPersonFullDTO>;
export type GetCurrentUserResult = AxiosResponse<UserDTO>;
export type TestBothResult = AxiosResponse<string>;
export type OfferRecoverPassword2Result = AxiosResponse<CheckCodeResponseDTO>;
export type GetPasswordPatternMetaResult = AxiosResponse<PasswordPatternDTO>;
export type RegisterResult = AxiosResponse<void>;
export type OfferRegisterResult = AxiosResponse<CreateSessionResponseDTO>;
export type OfferRecoverPasswordResult = AxiosResponse<void>;
