import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrganizationState, initialOrganizationState } from './state';
import { RootState } from '../Store';
import { CarePlanProductPriceDTO, OrganizationCUDTO, PractitionerProductPriceDTO, SaveStylesBody } from '@api/mainServiceAPI';
import { SettingsStepType } from 'src/enums/admin-settings-type.enum';
import { UserDirectDocumentDTO } from '@api/documentServiceAPI';
import { CustomThemeNames } from 'src/models/custom-themes';

// Slice
export const organizationSlice = createSlice({
    name: 'organization',
    initialState: initialOrganizationState,
    reducers: {
        setInitSettings: () => {},
        setRequisite: (state: IOrganizationState, action: PayloadAction<OrganizationCUDTO>) => {
            state.requisite = action.payload;
        },
        updateRequisite: (state: IOrganizationState, action: PayloadAction<OrganizationCUDTO>) => {},
        getRequisite: () => {},
        setActiveStep: (state: IOrganizationState, action: PayloadAction<SettingsStepType>) => {
            const step = state.steps.find(step => step.type ===  action.payload);
            if (step) {
                state.steps.forEach(step => step.active = false);
                step.active = true;
            }
        },
        setSaveDcouments: (state: IOrganizationState, action: PayloadAction<string[]>) => {},
        getDocuments: () => {},
        setDocuments: (state: IOrganizationState, action: PayloadAction<UserDirectDocumentDTO[]>) => {
            state.documents = action.payload;
        },
        getConsultationPrices: () => {},
        setConsultationPrices: (state: IOrganizationState, action: PayloadAction<PractitionerProductPriceDTO[]>) => {
            state.consultationPrices = action.payload;
        },
        updateConsultationPrices: (state: IOrganizationState, action: PayloadAction<CarePlanProductPriceDTO[]>) => {},
        getCoursePrices: () => {},
        setCoursePrices: (state: IOrganizationState, action: PayloadAction<CarePlanProductPriceDTO[]>) => {
            state.coursePrices = action.payload;
        },
        updateCoursePrices: (state: IOrganizationState, action: PayloadAction<CarePlanProductPriceDTO[]>) => {},
        setTheme: (state: IOrganizationState, action: PayloadAction<CustomThemeNames>) => {
            state.theme = action.payload;
        },
        setLogo: (state: IOrganizationState, action: PayloadAction<string>) => {
            state.logoURL = action.payload;
        },
        setTempLogo: (state: IOrganizationState, action: PayloadAction<string>) => {
            state.tempLogoUrl = action.payload;
        },
        getLogo: () => {},
        getPaymentServiceActive: () => {},
        setPaymentServiceActive: (state: IOrganizationState, action: PayloadAction<boolean>) => {
            state.paymentServiceActive = action.payload
        },
        getDeliveryAddressActive: () => {},
        setDeliveryAddressActive: (state: IOrganizationState, action: PayloadAction<boolean>) => {
            state.deliveryAddressActive = action.payload
        },
        updateStyles: (state: IOrganizationState, action: PayloadAction<SaveStylesBody>) => {},
        getStyles: () => {},
        updateLogo: (state: IOrganizationState, action: PayloadAction<string>) => {},
        setPreferencesEditing: (state: IOrganizationState, action: PayloadAction<boolean>) => {
            state.preferencesEditing = action.payload;
        },
        setProcessedStep: (state: IOrganizationState, action: PayloadAction<SettingsStepType>) => {
            const step = state.steps.find(step => step.type === action.payload);
            if(step) { 
                step.processed = true; 
            }
        },
        setReadonlyMode: (state: IOrganizationState, action: PayloadAction<boolean>) => {
            const readOnly = action.payload;
            state.steps.forEach((step) => step.readonly = readOnly);
        }
    }
});

// Actions
export const {
    setInitSettings,
    setRequisite,
    setDocuments,
    setActiveStep,
    setTheme,
    setLogo,
    getRequisite,
    getDocuments,
    updateRequisite,
    setSaveDcouments,
    updateConsultationPrices,
    getConsultationPrices,
    setConsultationPrices,
    getCoursePrices,
    setCoursePrices,
    updateCoursePrices,
    updateStyles,
    getStyles,
    updateLogo,
    setPreferencesEditing,
    getLogo,
    setTempLogo,
    getPaymentServiceActive,
    setPaymentServiceActive,
    getDeliveryAddressActive,
    setDeliveryAddressActive,
    setProcessedStep,
    setReadonlyMode
} = organizationSlice.actions;

// Reducer
export const organizationReducer = organizationSlice.reducer;

// Selectors
//export const selectCarePlan = (state: RootState) => state.patientReducer.carePlan;
export const selectRequisite = (state: RootState) => state.organizationReducer.requisite;
export const selectSteps = (state: RootState) => state.organizationReducer.steps;
export const selectStep = (state: RootState) => state.organizationReducer.steps.find(step => step.active);
export const selectDocuments = (state: RootState) => state.organizationReducer.documents;
export const selectConsultationPrices = (state: RootState) => state.organizationReducer.consultationPrices;
export const selectCoursePrices = (state: RootState) => state.organizationReducer.coursePrices;
export const selectCustomTheme = (state: RootState) => state.organizationReducer.theme;
export const selectLogo = (state: RootState) => state.organizationReducer.logoURL;
export const selectTempLogo = (state: RootState) => state.organizationReducer.tempLogoUrl;
export const selectPreferencesEditing = (state: RootState) => state.organizationReducer.preferencesEditing;
export const selectPaymentServiceActive = (state: RootState) => state.organizationReducer.paymentServiceActive;
export const selectDeliveryAddressActive = (state: RootState) => state.organizationReducer.deliveryAddressActive;


