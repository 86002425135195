import { GlobalLoader } from '@components';
import i18n from '@utils/i18n';
import dayjs from 'dayjs';
import { loadMessages, locale } from 'devextreme/localization';
import * as ruMessages from 'devextreme/localization/messages/ru.json';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HttpInterceptor } from './components/HttpInterceptor/HttpInterceptor';
import { selectCurrentLanguage, setCurrentLanguage } from './store/core/slice';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { useLocation } from 'react-router-dom';
import { FileViewer } from './components/FileViewer/FileViewer';
import { WebsocketContext } from './contexts/ws.context';
import { MainPage } from './pages/_general/MainPage/MainPage';
import { selectAuthUser, selectSwitchedToEndUser } from './store/user/slice';
import {getDeliveryAddressActive, getLogo, getPaymentServiceActive, getStyles} from './store/organization';
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);

// dayjs locales. Add more if necessary
// import('dayjs/locale/en');
import('dayjs/locale/ru');

export const App = () => {
    const { disconnect, wsClient } = useContext(WebsocketContext);

    const currentLanguage = useSelector(selectCurrentLanguage);
    const authUser = useSelector(selectAuthUser);
    const switchedToEndUser = useSelector(selectSwitchedToEndUser);

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPaymentServiceActive());
        dispatch(getDeliveryAddressActive());
        dispatch(getLogo());
        dispatch(getStyles());
    }, []);

    useEffect(() => {
        if (currentLanguage) {
            i18n.changeLanguage(currentLanguage);
            dayjs.locale(currentLanguage);
            loadMessages(ruMessages); // todo add other languages for DevExpress if necessary
            locale(currentLanguage);
        }
    }, [currentLanguage]);

    useEffect(() => {
        if (currentLanguage) {
            // checking if dayjs locale broke after a navigation
            setTimeout(() => {
                const currentDayjsLocale = dayjs.locale();
                if (currentDayjsLocale !== currentLanguage) {
                    dayjs.locale(currentLanguage);
                }
            });
        } else {
            // checking if currentLanguage value is missing
            dispatch(setCurrentLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE));
        }
    }, [location]);

    useEffect(() => {
        if (!authUser && !!wsClient) {
            disconnect();
        }
    }, [authUser, wsClient]);

    useEffect(() => {
        if (switchedToEndUser && !!wsClient) {
            disconnect();
        }
    }, [switchedToEndUser, wsClient]);

    return (
        <>
            <GlobalLoader />
            <HttpInterceptor />

            <MainPage />

            <FileViewer />
        </>
    );
};
